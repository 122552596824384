<template>
  <v-dialog v-model="dialog" width="650">
    <v-card>
      <v-card-title v-if="tab === 0"
        >Irtisano sopimuksen {{ currentContract.contractNumber }} vuokralaisia</v-card-title
      >
      <v-card-title v-if="tab === 1"
        >Lisää vuokralaisia sopimukselle {{ currentContract.contractNumber }}</v-card-title
      >

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            <div>{{ item }}</div>
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- REMOVE -->
      <v-card-text v-if="tab === 0">
        <h3 v-if="!selectableTenantsFound" class="mb-2 error--text">
          Vuokralaisia ei voi enää irtisanoa vuokrasopimukselta
        </h3>

        <h4 class="mb-1 success--text">Nykyiset vuokralaiset</h4>

        <!-- ACTIVE TENANTS -->
        <div v-for="(tenant, idx) in contractActiveTenants" :key="'a' + idx">
          <p :class="{ deleted: isRemoved(tenant.tenantId._id) }" class="ma-0">
            {{ tenant.name }}

            <span v-if="tenant.startDate">(Sisäänmuutto {{ formatDate(tenant.startDate) }})</span>
            <span v-else>(Sisäänmuutto {{ formatDate(currentContract.lease.startDate) }})</span>

            <span>
              <v-btn
                v-if="!isRemoved(tenant.tenantId._id) && canBeRemoved"
                icon
                small
                @click="selectTenant(tenant.tenantId._id)"
                ><v-icon small color="warning">mdi-redo</v-icon>
              </v-btn>

              <v-btn
                v-if="isRemoved(tenant.tenantId._id)"
                icon
                small
                @click="unSelectTenant(tenant.tenantId._id)"
              >
                <v-icon small color="success">mdi-undo</v-icon>
              </v-btn>
            </span>
          </p>
        </div>

        <!-- INACTIVE TENANTS -->
        <h4 v-if="contractInActiveTenants.length > 0" class="mb-1 mt-1 warning--text">
          Irtisanotut vuokralaiset
        </h4>

        <div v-for="(tenant, idx) in contractInActiveTenants" :key="'b' + idx">
          <p :class="{ deleted: isRemoved(tenant.tenantId._id) }" class="ma-0">
            {{ tenant.name }}
            <span v-if="tenant.startDate">{{
              `(Sisäänmuutto ${formatDate(tenant.startDate)})`
            }}</span>
            <span v-else>{{
              `(Sisäänmuutto ${formatDate(currentContract.lease.startDate)})`
            }}</span>
            (Irtisanottu {{ formatDate(tenant.revokedDate) }})
            <span>
              <v-btn icon small @click="deleteRevokedDate(tenant)">
                <v-icon small color="success">mdi-redo</v-icon>
              </v-btn>
            </span>
          </p>
        </div>

        <div class="mt-2" v-if="this.revokedTenants.length > 0">
          <strong style="font-size: 12px"
            >Irtisanoaksesi valitut vuokralaiset sopimukselta, aseta irtisanomispäivä ja paina
            irtisano. Irtisanotut vuokralaiset voi palauttaa takaisin toimenpiteen jälkeen.</strong
          >
        </div>

        <!-- FORM -->
        <v-form ref="revokeForm">
          <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="formatDate(revokedDate)"
                dense
                outlined
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                label="Irtisanomispäivä"
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="revokedDate"
              first-day-of-week="1"
              @input="endMenu = false"
              :allowed-dates="availableEndDates"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>

      <!-- ADD -->
      <v-card-text v-if="tab == 1">
        <v-form ref="addForm">
          <v-autocomplete
            v-model="selectedTenant"
            :items="activeTenants"
            :item-text="tenantNameAndGovid"
            item-value="_id"
            label="Vuokralainen"
            no-data-text="Vuokralaisen nimi"
            outlined
            dense
            small-chips
            hide-details
            return-object
            @input.native="getTenants"
            :rules="validations.required"
          >
          </v-autocomplete>

          <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="formatDate(startDate)"
                dense
                outlined
                v-bind="attrs"
                :rules="validations.required"
                v-on="on"
                append-icon="mdi-calendar"
                label="Sisäänmuuttopäivä"
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              first-day-of-week="1"
              @input="startMenu = false"
              :allowed-dates="availableStartDates"
            ></v-date-picker>
          </v-menu>
        </v-form>

        <warningComponent v-if="selectedTenant"
          >Huom! Sopimukselle lisättyä vuokralaista ei voi enää poistaa, mutta sen voi irtisanoa
          tämän ikkunan "Irtisano"-välilehdeltä.</warningComponent
        >
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="tab === 0"
          :disabled="!selectableTenantsFound"
          color="info"
          @click="revokeTenants"
          >Irtisano</v-btn
        >
        <v-btn v-if="tab === 1" color="info" @click="addTenants">Lisää</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tab: 0,
      tabs: ["Irtisano", "Lisää"],
      startMenu: false,
      endMenu: false,
      tenantInput: "",
      startDate: null,
      revokedDate: null,
      revokedTenants: [],
      selectedTenant: null,
      validations,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("contract", ["currentContract"]),
    ...mapState("tenant", ["activeTenants"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.revokedTenants = [];
          this.selectedTenant = null;
          this.startDate = null;
          this.revokedDate = null;
          if (this.$refs.addForm) this.$refs.addForm.reset();
          if (this.$refs.revokeForm) this.$refs.revokeForm.reset();
        }
        this.$emit("input", value);
      },
    },

    contractActiveTenants() {
      if (this.currentContract.tenant && this.currentContract.otherTenants) {
        const tenant = !this.currentContract.tenant.revokedDate
          ? [this.currentContract.tenant]
          : [];
        const others = this.currentContract.otherTenants.filter((tenant) => !tenant.revokedDate);
        return [...tenant, ...others];
      } else return [];
    },

    contractInActiveTenants() {
      if (this.currentContract.tenant && this.currentContract.otherTenants) {
        const tenant = this.currentContract.tenant.revokedDate ? [this.currentContract.tenant] : [];
        const others = this.currentContract.otherTenants.filter((tenant) => tenant.revokedDate);
        return [...tenant, ...others];
      } else return [];
    },

    selectableTenantsFound() {
      return this.contractActiveTenants.length > 1;
    },

    canBeRemoved() {
      return this.contractActiveTenants.length - this.revokedTenants.length > 1;
    },
  },

  watch: {
    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("contract", [
      "setRevokedDateToTenant",
      "revertRevokedTenant",
      "addTenantToContract",
    ]),
    ...mapActions("tenant", ["searchTenant"]),

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    selectTenant(id) {
      this.revokedTenants.push(id);
      this.showPopup("Vuokralainen siirretty irtisanomislistalle", "success");
    },

    tenantNameAndGovid(item) {
      const govid = item.isCompany ? item.businessId || "" : item.social || "";
      return `${item.name} (${govid})`;
    },

    unSelectTenant(id) {
      const index = this.revokedTenants.findIndex((elId) => elId === id);
      if (index != -1) {
        this.revokedTenants.splice(index, 1);
        this.showPopup("Vuokralainen poistettu irtisanomislistalta", "success");
      }
    },

    isRemoved(id) {
      return this.revokedTenants.includes(id);
    },

    async revokeTenants() {
      try {
        if (this.revokedTenants.length === 0)
          return this.showPopup("Valitse vähintään yksi vuokralainen", "error");
        if (!this.$refs.revokeForm.validate()) return this.showPopup("Tarkista lomake", "error");

        // Check if dates are ok
        const selectedTenants = this.contractActiveTenants.filter((el) =>
          this.revokedTenants.some((id) => String(id) === String(el.tenantId._id))
        );

        for (const tenant of selectedTenants) {
          const tenantStart = tenant.startDate || null;
          if (tenantStart) {
            const startDate = moment(tenantStart).format("YYYY-MM-DD");
            const revokedDate = moment(this.revokedDate).format("YYYY-MM-DD");

            if (moment(startDate).isAfter(revokedDate)) {
              throw new Error("Irtisanomispäivä ei voi olla ennen sisäänmuuttopäivää");
            }
          }
        }

        await this.setRevokedDateToTenant({
          rentalContractId: this.currentContract._id,
          selected: this.revokedTenants,
          revokedDate: this.revokedDate,
        });

        this.revokedTenants = [];
        this.$refs.revokeForm.reset();

        this.showPopup("Vuokralainen poistettu nykyiseltä sopimukselta", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async addTenants() {
      try {
        if (!this.$refs.addForm.validate()) return this.showPopup("Tarkista lomake", "error");

        if (confirm("Oletko varma että haluat lisätä valitun vuokralaisen sopimukselle?")) {
          await this.addTenantToContract({
            rentalContractId: this.currentContract._id,
            tenantId: this.selectedTenant._id,
            startDate: this.startDate,
          });

          this.$refs.addForm.reset();

          this.showPopup("Vuokralainen lisätty sopimukselle", "success");
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteRevokedDate(tenant) {
      if (
        confirm(
          `Haluatko palauttaa vuokralaisen ${tenant.name} sopimukselle ja poistaa vanhan irtisanomispäivän?`
        )
      ) {
        try {
          await this.revertRevokedTenant({
            rentalContractId: this.currentContract._id,
            tenantId: tenant.tenantId._id,
          });

          this.revokedTenants = [];
          this.$refs.revokeForm.reset();

          this.showPopup("Vuokralainen palautettu nykyiselle sopimukselle", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    availableEndDates(val) {
      const leaseType = this.currentContract.lease.leaseType;
      const contractStart = moment(this.currentContract.lease.startDate).format("YYYY-MM-DD");
      const contractEnd = this.currentContract.lease.endDate
        ? moment(this.currentContract.lease.endDate).format("YYYY-MM-DD")
        : null;

      if (leaseType === "Toistaiseksi voimassa oleva") {
        return moment(val).isSameOrAfter(contractStart);
      } else {
        return moment(val).isSameOrAfter(contractStart) && moment(val).isSameOrBefore(contractEnd);
      }
    },

    availableStartDates(val) {
      const leaseType = this.currentContract.lease.leaseType;
      const contractStart = moment(this.currentContract.lease.startDate).format("YYYY-MM-DD");
      const contractEnd = this.currentContract.lease.endDate
        ? moment(this.currentContract.lease.endDate).format("YYYY-MM-DD")
        : null;

      if (leaseType === "Toistaiseksi voimassa oleva") {
        return moment(val).isSameOrAfter(contractStart);
      } else {
        return moment(val).isSameOrAfter(contractStart) && moment(val).isSameOrBefore(contractEnd);
      }
    },
  },
};
</script>

<style scoped>
.deleted {
  text-decoration: line-through;
}
</style>
